<template src='./secured.html'></template>
<style src='./secured.scss' lang='scss' scoped></style>

<script>
import Checkbox from '@/components/forms/Checkbox'
import { getUser } from '@/api/user'
import Vue from 'vue'
import { ClientTable } from 'vue-tables-2'
import vueTablesConf from '@/config/vue-tables'

Vue.use(ClientTable, vueTablesConf.options, vueTablesConf.useVuex, vueTablesConf.theme, vueTablesConf.template)

export default {
  name: 'secured',
  components: {
    Checkbox
  },
  data () {
    return {
      secretText: 'This is restricted area. Ony chosen can see this text',
      userInfo: null,
      isChecked: true,
      columns: ['id', 'name', 'age'],
      tableData: [
        { id: 1, name: ['John', 'John'], age: '20' },
        { id: 2, name: ['Jane', 'Jane'], age: '24' },
        { id: 3, name: ['Susan', 'Susan'], age: '16' },
        { id: 4, name: ['Chris', 'Chris'], age: '55' },
        { id: 5, name: ['Dan', 'Dan'], age: '40' }
      ],
      options: {
        filterByColumn: true,
        listColumns: {
          id: [{
            id: '',
            text: 'TEST DSDSD'
          },
          {
            id: 'John',
            text: 'John'
          },
          {
            id: 2,
            text: '2'
          },
          {
            id: 3,
            text: '3'
          },
          {
            id: 4,
            text: '4'
          }]
        },
        headings: {
          id: 'ID',
          name: 'ИМЯ',
          age: 'Возраст'
        }
      }
    }
  },
  methods: {
    async requestUserInfo () {
      try {
        const response = await getUser()
        this.userInfo = response.data
      } catch (e) {

      }
    }
  },
  created () {
    this.requestUserInfo()
  }
}
</script>
